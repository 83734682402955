import React, { Component } from "react"
import Headermenu from "../containers/RealestatePage5/HeaderMenuBS"
import Banner from "../containers/RealestatePage5/Banner"
import Features from "../containers/RealestatePage5/Features"
import About from "../containers/RealestatePage5/About"
import Obsessions from "../containers/RealestatePage5/AboutUs"
import Stats from "../containers/RealestatePage5/Stats"
import Properties from "../containers/RealestatePage5/Properties"
import Amenities from "../containers/RealestatePage5/Amenities"
import Neighbour from "../containers/RealestatePage5/Neighbour"
import Team from "../containers/RealestatePage5/Team"
import Testimonials from "../containers/RealestatePage5/Testimonials"
import Gallery from "../containers/RealestatePage5/Gallery"
import Footer from "../containers/RealestatePage5/Footer"
import GlobalStyle from "../containers/RealestatePage5/Common/global-styles"
import Fonts from "../containers/RealestatePage5/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

class RealestatePage5 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenLightBox: false,
      lightBoxImages: [],
      photoIndex: 0,
    }
    this.openLightBox = this.openLightBox.bind(this)
    this.openLightBoxAbout = this.openLightBoxAbout.bind(this)
  }

  openLightBox(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.Slider.entries()) {
      console.log(index)
      images.push(value.GallerySliderImg.childImageSharp.fluid.src)
    }
    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }
  openLightBoxAbout(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.SliderData.entries()) {
      console.log(index)
      images.push(value.Img.childImageSharp.fluid.src)
    }
    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }

  render() {
    return (
      <div>
        {
          <div>
            <Fonts />
            <GlobalStyle />
            <main>
              <SEO
                title="Leslie Rayser | Real Estate"
                description="Your guide to buying and selling homes in South Jersey ( Collingswood, Haddon Heights, Westmont and Haddonfield.)"
              />
              <Headermenu />
              <Banner />
              <Obsessions openLightBoxAbout={this.openLightBoxAbout} />
              <Features />
              {/* <Properties /> */}
              <About />
              {/* <Gallery openLightBox={this.openLightBox} /> */}
            </main>
            <Footer />
          </div>
        }
        {this.state.isOpenLightBox && (
          <Lightbox
            mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
            nextSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + 1) % this.state.lightBoxImages.length
              ]
            }
            prevSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + this.state.lightBoxImages.length - 1) %
                  this.state.lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpenLightBox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex +
                    this.state.lightBoxImages.length -
                    1) %
                  this.state.lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) %
                  this.state.lightBoxImages.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default RealestatePage5
